<template>
  <!-- 用户管理 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item v-if="isAdmin" label="集团名称：">
        <el-input placeholder="请输入集团名称" maxlength="15" :value="queryForm.group_name"
          @input="(e) => (queryForm.group_name = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>

      <el-form-item label="用户名：">
        <el-input placeholder="请输入用户名" maxlength="15" :value="queryForm.user_name"
          @input="(e) => (queryForm.user_name = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>

      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="用户管理">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="deletefu" v-if="isShowBtn('c259021020524eedb7970783')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="addfu" v-if="isShowBtn('524d76d350604b8eadecf6ff')">新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="valid_period_to">
            <span v-if="isLongTime(row.valid_period_to)">永久</span>
            <span v-else>{{ validDate(row.valid_period_to) }}</span>
          </template>
          <template slot-scope="{row}" slot="Last_login">
            <span>{{ validDateTime(row.Last_login) }}</span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            <span>{{ validDateTime(row.create_time) }}</span>
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="editit(row)"
              v-if='isShowBtn("9b2d57e673e94879a6ab3140")'></lbButton>
            <lbButton icon="zhongzhimima" hint="重置密码" @click="restPassWord(row)"
              v-if="isShowBtn('9b2d57e673e94879a6ab3144')"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column v-if="isAdmin" prop="group_name" label="集团名称"></el-table-column>
          <el-table-column prop="user_name" label="用户名"></el-table-column>
          <el-table-column prop="role_name" label="角色权限"></el-table-column>
          <el-table-column v-if="!isAdmin" prop="remarks" label="备注"></el-table-column>
          <el-table-column v-if="isAdmin" label="有效期至">
            <template slot-scope="scope">
              <span v-if="isLongTime(scope.row.valid_period_to)">永久</span>
              <span v-else>{{ validDate(scope.row.valid_period_to) }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="isAdmin" label="最近登录时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.Last_login) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_user_name" v-if="isAdmin" label="创建者"></el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.create_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editit(scope.row)"
                v-if='isShowBtn("9b2d57e673e94879a6ab3140")'></lbButton>
              <lbButton icon="zhongzhimima" hint="重置密码" @click="restPassWord(scope.row)"
                v-if="isShowBtn('9b2d57e673e94879a6ab3144')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
      </div>
    </le-card>
    <!-- 用户管理 -->
    <lebo-dialog :isShow="showUserEdit" width="50%" title="用户管理" :footerSlot="true" @close="showUserEdit = false">
      <userEdit v-if="showUserEdit" @closeDialog="closeUserEdit"></userEdit>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import request from '@/api/systemapi'
import userEdit from './components/userEdit/index.vue'
export default {
  components: { userEdit },
  data () {
    return {
      // 查询表单
      queryForm: {
        group_name: '',
        user_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      dialogForm: {
        name: ''
      },
      dialogRules: {
        name: [{ required: true, message: '请输集团名称', trigger: 'blur' }]
      },
      TotalCount: 0,
      selectArray: [],
      showUserEdit: false,
      dialogUserEditId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '集团名称',
            prop: 'group_name'
          }, {
            label: '用户名',
            prop: 'user_name'
          }, {
            label: '角色权限',
            prop: 'role_name'
          }, {
            label: '备注',
            prop: 'remarks',
            slot: true
          }, {
            label: '有效期至',
            prop: 'valid_period_to',
            slot: true
          }, {
            label: '最近登录时间',
            prop: 'Last_login',
            slot: true
          }, {
            label: '创建者',
            prop: 'create_user_name'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    console.log(this.queryForm)
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getUserInfo', 'getOptions', 'getWayName'])
  },
  mounted () {
    this.requestUserList()
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestUserList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestUserList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestUserList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestUserList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestUserList()
    // },
    // 编辑
    editit (row) {
      sessionStorage.setItem('userEdit', JSON.stringify({ row: row, isedit: true }))
      this.showUserEdit = true
    },
    async restPassWord (row) {
      this.$confirm('是否重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await request.resetPwd({
          _id: row._id,
          password: '88888888'
        })
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
          this.requestUserList()
        }
      }).catch(() => {

      })
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (oldArray) {
      // console.log(oldArray);
      const newArray = []
      for (const item of oldArray) {
        newArray.push(item._id)
      }
      this.selectArray = newArray
    },
    // // 选中的数据
    // selectionChange (oldArray) {
    //   const newArray = []
    //   for (const item of oldArray) {
    //     newArray.push(item._id)
    //   }
    //   this.selectArray = newArray
    // },
    addfu () {
      this.showUserEdit = true
    },
    async deletefu () {
      var that = this
      if (this.selectArray.length === 0) {
        this.$msg.warning('请选择要删除的条目！')
        return
      }
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await request.userDelete({ _ids: this.selectArray })
          if (res && res.Code === 200) {
            this.$msg.success(res.Message)
          }
          that.requestUserList()
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    async requestUserList () {
      const res = await request.getUserList(this.queryForm)
      this.tableData = res.Data.DataList
      this.TotalCount = res.Data.TotalCount
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    closeUserEdit () {
      this.showUserEdit = false
      this.requestUserList()
    }
  }
}
</script>

<style scoped lang="less">
.demo-form-inline {
  /* float: left; */
  display: flex;
  justify-content: start;
}

/* // .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// } */
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}
</style>
